import { useEffect, useMemo, useRef, useState } from 'react';
import { TabCard, Tooltip } from '@cimpress/react-components';
import { DecisionViewerProps } from './DecisionViewer';
import { SpinnerWrapper } from './SpinnerWrapper';
import { DecisionTab } from './DecisionTab';
import { useOrchestrateRoutingItem } from '../hooks/useOrchestrateRoutingItem';
import { useAsyncError } from '../hooks/useAsyncError';

const TOOLTIP_MESSAGES = {
  0: 'Represents the decision between the buyer and seller',
  1: 'Represents the decision between the seller and their third-party fulfillers (3PF). The seller can choose themself or a 3PF.',
} as { [key: number]: string };

export const DecisionViewerBody = ({
  decisionLink,
  showDecisionLinks,
  item,
  decisionJson,
  halfWeight,
}: DecisionViewerProps) => {
  const tabRef = useRef<HTMLDivElement | null>(null);
  const tooltipWrapperRef = useRef<HTMLDivElement | null>(null);

  // Default to selecting the current decision tab
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [tabNameTooltipX, setTabNameTooltipX] = useState<string | undefined>(undefined);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState<string | undefined>(undefined);
  const [tooltipDirection, setTooltipDirection] = useState<{ direction: 'top' | 'bottom'; offset: string }>({
    direction: 'top',
    offset: '-10px',
  });

  const throwAsyncError = useAsyncError();

  const { data, isLoading, error } = useOrchestrateRoutingItem({
    item,
    currentRoutingDecisionUrl: decisionLink,
    currentRoutingDecision: decisionJson,
    showDecisionLinks,
  });

  const {
    currentItem,
    initialItem,
    initialItemRoutingRequestUrl,
    initialRoutingDecisionUrl,
    initialRoutingDecision,
    currentItemRoutingRequestUrl,
    currentRoutingDecision,
    currentRoutingDecisionUrl,
    diff,
  } = data || {};

  const tabs = useMemo(
    () => [
      {
        name: 'Initial Decision ⓘ',
        block: (
          <DecisionTab
            item={initialItem}
            decisionLink={initialRoutingDecisionUrl}
            routingDecision={initialRoutingDecision}
            routingRequestUrl={initialItemRoutingRequestUrl}
            showDecisionLinks={showDecisionLinks}
            halfWeight={halfWeight}
            diff={diff}
          />
        ),
        href: '#',
      },
      {
        name: 'Current Decision ⓘ',
        block: (
          <DecisionTab
            item={currentItem}
            routingDecision={currentRoutingDecision}
            decisionLink={currentRoutingDecisionUrl || currentRoutingDecision?._links?.self?.href}
            routingRequestUrl={currentItemRoutingRequestUrl}
            showDecisionLinks={showDecisionLinks}
            halfWeight={halfWeight}
            diff={diff}
          />
        ),
        href: '#',
      },
    ],
    [
      initialItem,
      initialItemRoutingRequestUrl,
      initialRoutingDecision,
      initialRoutingDecisionUrl,
      currentItem,
      currentRoutingDecision,
      currentRoutingDecisionUrl,
      currentItemRoutingRequestUrl,
      showDecisionLinks,
      diff,
      halfWeight,
    ],
  );

  // Add mouse event listeners to the unordered list used for the
  // tab names so that we can hook into the hover event for the tab
  // names. The <TabCard /> component doesn't provide a prop for this.
  useEffect(() => {
    if (!tabRef.current) {
      return;
    }

    const list = tabRef.current.querySelector('ul');
    if (!list) {
      return;
    }

    const listItems = list.querySelectorAll('li');
    if (!listItems) {
      return;
    }

    // When hovering over a tab name, this function will
    // adjust the tooltip position, set the message shown,
    // and display the tooltip
    const showTooltipOnTabNameMouseOver = (e: MouseEvent) => {
      if (!tabRef.current || !e.target) {
        return;
      }

      const hoveredTabIndex = tabs.findIndex((tab) => tab.name === (e.target as HTMLElement).innerText);

      const selectedListItem = listItems[hoveredTabIndex];
      if (!selectedListItem) {
        return;
      }

      const itemBoundingBox = selectedListItem.getBoundingClientRect();

      // Position the tooltip over the center of the selected tab
      if (hoveredTabIndex === 0) {
        setTabNameTooltipX(`${itemBoundingBox.width / 2}px`);
      } else {
        setTabNameTooltipX(`${selectedListItem.offsetLeft + itemBoundingBox.width / 2}px`);
      }

      // 100px is minimum space needed to show tooltips above the routing
      // decision
      if (tabRef.current.getBoundingClientRect().top < 100) {
        setTooltipDirection({ direction: 'bottom', offset: '20px' });
      } else {
        setTooltipDirection({ direction: 'top', offset: '-10px' });
      }

      setTooltipMessage(TOOLTIP_MESSAGES[hoveredTabIndex]);
      setShowTooltip(true);
    };

    const hideTooltipOnTabNameMouseOut = () => {
      setShowTooltip(false);
    };

    listItems.forEach((listItem) => {
      listItem.addEventListener('mouseenter', showTooltipOnTabNameMouseOver);
      listItem.addEventListener('mouseleave', hideTooltipOnTabNameMouseOut);
    });

    return () => {
      listItems.forEach((listItem) => {
        listItem.removeEventListener('mouseenter', showTooltipOnTabNameMouseOver);
        listItem.removeEventListener('mouseleave', hideTooltipOnTabNameMouseOut);
      });
    };
  }, [tabs]);

  if (isLoading) {
    return <SpinnerWrapper />;
  }

  if (error) {
    throwAsyncError(error.error, error.context);
  }

  return (
    <div>
      {initialRoutingDecisionUrl && (
        <>
          <div
            ref={tooltipWrapperRef}
            className="tooltip-wrapper"
            style={{ position: 'absolute', top: tooltipDirection.offset, left: tabNameTooltipX }}
          >
            <Tooltip
              contents={tooltipMessage}
              show={showTooltip}
              direction={tooltipDirection.direction}
              style={{ width: 'auto' }}
              tooltipInnerStyle={{ maxWidth: '300px' }}
            >
              {' '}
            </Tooltip>
          </div>
          <div id="tabcard-wrapper" ref={tabRef}>
            <TabCard
              tabs={tabs}
              selectedIndex={selectedIndex}
              onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const selectedTab = tabs.findIndex((tab) => tab.name === e.target.innerText);
                setSelectedIndex(selectedTab);
              }}
            />
          </div>
        </>
      )}
      {!initialRoutingDecisionUrl && (
        <DecisionTab
          item={currentItem}
          routingDecision={currentRoutingDecision}
          decisionLink={currentRoutingDecisionUrl || currentRoutingDecision?._links?.self?.href}
          routingRequestUrl={currentItemRoutingRequestUrl}
          showDecisionLinks={showDecisionLinks}
          halfWeight={halfWeight}
          diff={diff}
        />
      )}
    </div>
  );
};
