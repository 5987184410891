/* eslint-disable react/jsx-no-useless-fragment */
import React, { useLayoutEffect } from 'react';
import { injectGlobal, css } from '@emotion/css';
import cvar from './theme/cvar';

const CIM_UI_STYLE_ROOT = '[data-cim-style-root]';

function createCompatibleSelector(...selectors: string[]): string {
  return selectors
    .map(selector => `${selector}:where(:not(${CIM_UI_STYLE_ROOT}):not(${CIM_UI_STYLE_ROOT} ${selector}))`)
    .join(', ');
}

const globalStyles = css`
  ${createCompatibleSelector('*')} {
    margin: 0;
    padding: 0;
    font-size: 1em;
    font-family: inherit;
  }

  ${createCompatibleSelector('body')} {
    font: ${cvar('text-paragraph-default')};
    color: ${cvar('color-text-default')};
  }

  ${createCompatibleSelector('h1', 'h2', 'h3', 'h4', 'h5', 'h6', '.h1', '.h2', '.h3', '.h4', '.h5', '.h6', '.h7')} {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  ${createCompatibleSelector('h1', '.h1')} {
    font: ${cvar('text-h1')};
  }

  ${createCompatibleSelector('h2', '.h2')} {
    font: ${cvar('text-h2')};
  }

  ${createCompatibleSelector('h3', '.h3')} {
    font: ${cvar('text-h3')};
  }

  ${createCompatibleSelector('h4', '.h4')} {
    font: ${cvar('text-h4')};
  }

  ${createCompatibleSelector('h5', '.h5')} {
    font: ${cvar('text-h5')};
  }

  ${createCompatibleSelector('h6', '.h6')} {
    font: ${cvar('text-h6')};
    text-transform: uppercase;
  }

  ${createCompatibleSelector('.h7')} {
    font: ${cvar('text-h7')};
    color: ${cvar('color-text-label')};
    text-transform: uppercase;
  }

  ${createCompatibleSelector('a')} {
    color: ${cvar('color-text-interactive')};
    text-decoration: none;

    &:hover {
      color: ${cvar('color-text-hover')};
      text-decoration: underline;
    }

    &:active {
      color: ${cvar('color-text-selected')};
      text-decoration: underline;
    }
  }

  ${createCompatibleSelector('code', 'kbd', 'pre', 'samp')} {
    font-family: ${cvar('font-monospace')};
  }

  ${createCompatibleSelector('code')} {
    padding: 2px 4px;
    font-size: 90%;
    border-radius: 2px;
  }

  ${createCompatibleSelector('pre')} {
    display: block;
    padding: ${cvar('spacing-8')}px;
    margin: 0 0 9px;
    font-size: 13px;
    line-height: 1.286;
    word-break: break-all;
    word-wrap: break-word;
    border: 1px solid;
    border-radius: 2px;
  }

  ${createCompatibleSelector('pre code')} {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0;
  }
`;

// Modified version of modern-normalize v1.1.0 (https://github.com/sindresorhus/modern-normalize)
// CSS comments were removed to save on bundle size
// Removed some rules to avoid repetition in `globalStyles`
const modernNormalizeStyles = css`
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    tab-size: 4;
  }

  ${createCompatibleSelector('body')} {
    margin: 0;
  }

  ${createCompatibleSelector('hr')} {
    height: 0;
    color: inherit;
  }

  ${createCompatibleSelector('abbr[title]')} {
    text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: bolder;
  }

  code,
  kbd,
  samp,
  pre {
    font-family: ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
    font-size: 1em;
  }

  ${createCompatibleSelector('small')} {
    font-size: 85%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  ${createCompatibleSelector('table')} {
    text-indent: 0;
    border-color: inherit;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  ${createCompatibleSelector('button', 'select')} {
    text-transform: none;
  }

  ${createCompatibleSelector('button', "[type='button']", "[type='reset']", "[type='submit']")} {
    -webkit-appearance: button;
    cursor: pointer;
  }

  ::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  :-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  legend {
    padding: 0;
  }

  progress {
    vertical-align: baseline;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }

  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  summary {
    display: list-item;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
injectGlobal`
  ${modernNormalizeStyles};
  ${globalStyles};
`;

// TODO: migrate to Emotion v11 which uses the `<Global />` component for global styles
export const GlobalStyles = () => {
  // Inject Google Fonts stylesheet
  useLayoutEffect(() => {
    const existingLink = document.querySelector('link[data-ccl-font="true"]');
    if (existingLink) return;

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap';
    link.dataset.cclFont = 'true';
    document.head.appendChild(link);
  }, []);

  return <></>;
};
