import { useContext, useState } from 'react';
import { get } from 'lodash';
import { useGetDecisionFromUrl } from './useOrca';
import { useGetItemByItemId } from './useItemService';
import { getRoutingRequestUrlFromItem } from '../helpers/item';
import { useGetItemRoutingRequest } from './useRequestManager';
import { FailedDecision, SuccessfulDecision, ItemRoutingRequestResponse, Item } from '../types';
import { AccessContext } from '../components/DecisionViewer';
import { ErrorContext, RoutingUIError } from '../components/ErrorBoundary/RoutingUIError';

type UseOrchestrateRoutingItemParams = {
  item: Item | undefined;
  currentRoutingDecisionUrl: string | undefined;
  currentRoutingDecision: string | undefined;
  showDecisionLinks: boolean | undefined;
};

export const useOrchestrateRoutingItem = ({
  item,
  currentRoutingDecisionUrl,
  currentRoutingDecision,
  showDecisionLinks,
}: UseOrchestrateRoutingItemParams) => {
  const { accessToken } = useContext(AccessContext);

  const [error, setError] = useState<{ error: RoutingUIError | Error; context?: ErrorContext } | undefined>();
  const [currentItemRoutingRequestUrl, setCurrentItemRoutingRequestUrl] = useState<string | undefined>();
  const [currentDecisionItemRoutingRequest, setCurrentDecisionItemRoutingRequest] = useState<
    ItemRoutingRequestResponse | undefined
  >();
  const [initialRoutingDecisionUrl, setInitialRoutingDecisionUrl] = useState<string | undefined>();
  const [initialRoutingDecision, setInitialRoutingDecision] = useState<
    SuccessfulDecision | FailedDecision | undefined
  >();
  const [initialDecisionItem, setInitialDecisionItem] = useState<Item | undefined>();
  const [initialDecisionItemRoutingRequest, setInitialDecisionItemRoutingRequest] = useState<
    ItemRoutingRequestResponse | undefined
  >();

  // Either use the existing decision passed in (currentRoutingDecision) or
  // get the current decision from the link provided
  const currentDecision = useGetDecisionFromUrl({
    accessToken,
    decisionLink: currentRoutingDecisionUrl,
    enabled: !currentRoutingDecision && !!currentRoutingDecisionUrl,
    onError: (error) => {
      setError({
        error,
        context: {
          item, // May or may not be defined depending on when hook is called
          decisionLink: currentRoutingDecisionUrl,
          showDecisionLinks, // Should have the decision link
        },
      });
    },
  });

  const { isLoading: isRoutingDecisionLoading } = currentDecision;
  let { data: routingDecision } = currentDecision;

  if (currentRoutingDecision) {
    routingDecision = JSON.parse(currentRoutingDecision) as FailedDecision | SuccessfulDecision;
  }

  // Once we have the current routing decision, get the item routing request
  // from it
  const { isLoading: isLoadingCurrentItemRoutingRequest } = useGetItemRoutingRequest({
    accessToken,
    routingRequestUrl:
      routingDecision?._links?.itemRoutingRequest?.href || routingDecision?._links?.routingRequest?.href,
    enabled: !!routingDecision,
    onSuccess: (data) => {
      // Check to see if the request has an initiated decision
      // This means MOTR was used
      const initialDecision = get(data, 'routingRequest._links.initiatedDecision.href') as string | undefined;

      if (initialDecision) {
        setInitialRoutingDecisionUrl(initialDecision);
      }

      setCurrentDecisionItemRoutingRequest(data.routingRequest);
    },
    onError: (error) => {
      setError({
        error,
        context: {
          routingDecision,
          decisionLink: currentRoutingDecisionUrl,
          showDecisionLinks,
        },
      });
    },
  });

  // Using the item routing request from the current decision,
  // get the item
  const currentItemQuery = useGetItemByItemId({
    accessToken,
    decisionLink: currentRoutingDecisionUrl,
    itemLink: currentDecisionItemRoutingRequest?._links?.item?.href,
    enabled: !item && !!currentDecisionItemRoutingRequest,
    onSuccess: (data) => {
      setCurrentItemRoutingRequestUrl(getRoutingRequestUrlFromItem(data));
    },
    onError: (error) => {
      setError({ error });
    },
  });

  const { isLoading: isLoadingCurrentItem } = currentItemQuery;
  let { data: currentItem } = currentItemQuery;

  if (item) {
    currentItem = item;
  }

  // Get the initial routing decision if there's an initial routing decision URL
  const { isLoading: isLoadingInitialItemDecision } = useGetDecisionFromUrl({
    accessToken,
    decisionLink: initialRoutingDecisionUrl,
    enabled: !!initialRoutingDecisionUrl,
    onSuccess: (data) => {
      setInitialRoutingDecision(data);
    },
    onError: (error) => {
      setError({
        error,
        context: {
          decisionLink: initialRoutingDecisionUrl,
          showDecisionLinks,
        },
      });
    },
  });

  // Get the routing request for the initial routing decision if there's an
  // initial routing decision
  const { isLoading: isLoadingInitialItemRoutingRequest } = useGetItemRoutingRequest({
    accessToken,
    routingRequestUrl:
      initialRoutingDecision?._links?.itemRoutingRequest?.href || initialRoutingDecision?._links?.routingRequest?.href,
    enabled: !!initialRoutingDecision,
    onSuccess: (data) => {
      setInitialDecisionItemRoutingRequest(data.routingRequest);
    },
    onError: (error) => {
      setError({
        error,
        context: {
          routingDecision: initialRoutingDecision,
          decisionLink: initialRoutingDecisionUrl,
          showDecisionLinks,
        },
      });
    },
  });

  const { isLoading: isLoadingInitialItem } = useGetItemByItemId({
    accessToken,
    decisionLink: initialRoutingDecisionUrl,
    itemLink: initialDecisionItemRoutingRequest?._links.item.href,
    enabled: !!initialRoutingDecisionUrl && !!initialDecisionItemRoutingRequest,
    onSuccess: (data) => {
      setInitialDecisionItem(data);
    },
    onError: (error) => {
      setError({
        error,
        context: {
          routingDecision: initialRoutingDecision,
          decisionLink: initialRoutingDecisionUrl,
          showDecisionLinks,
        },
      });
    },
  });

  if (
    isLoadingCurrentItem ||
    isLoadingCurrentItemRoutingRequest ||
    isLoadingInitialItem ||
    isLoadingInitialItemDecision ||
    isLoadingInitialItemRoutingRequest ||
    isRoutingDecisionLoading
  ) {
    return {
      isLoading: true,
      data: {},
      error: undefined,
    };
  }

  if (error) {
    return {
      isLoading: false,
      data: {},
      error,
    };
  }

  const diff = new Set<string>();
  if (initialDecisionItem && currentItem) {
    if (initialDecisionItem.orderedSkuCode !== currentItem.orderedSkuCode) {
      diff.add('sku');
      diff.add('routingConfiguration');
    }

    if (initialDecisionItem.merchantId !== currentItem.merchantId) {
      diff.add('merchantId');
    }
  }

  if (initialRoutingDecision && currentRoutingDecision) {
    const initialNetworkOperator = get(initialRoutingDecision, '_links.networkOperator.name');
    const currentNetworkOperator = get(currentRoutingDecision, '_links.networkOperator.name');
    if (initialNetworkOperator && currentNetworkOperator && initialNetworkOperator !== currentNetworkOperator) {
      diff.add('routingConfiguration');
    }
  }

  return {
    isLoading: false,
    data: {
      currentItem,
      initialItem: initialDecisionItem,
      initialItemRoutingRequestUrl: initialRoutingDecision?._links?.itemRoutingRequest?.href,
      initialRoutingDecisionUrl,
      initialRoutingDecision,
      currentItemRoutingRequestUrl: currentItemRoutingRequestUrl || getRoutingRequestUrlFromItem(currentItem),
      currentRoutingDecision: routingDecision,
      currentRoutingDecisionUrl,
      diff,
    },
  };
};
